
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import ProfileSettings from '@/components/user/ProfileSettings.vue'

@Options({
  components: { ProfileSettings },
  directives: { maska },
})
export default class UserProfile extends Vue {}
