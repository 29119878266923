
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IUser } from '@/utils/types'
import { ACTION_USER } from '@/store/actions'
import UserMixin from '@/components/user/mixins/UserMixin.vue'
import UserFormModal from '@/components/user/UserFormModal.vue'
import cloneDeep from 'lodash/cloneDeep'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Watch } from 'vue-property-decorator'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: {
    UserMixin,
    UserFormModal,
    CLoading,
  },
  directives: { maska },
})
export default class UserList extends mixins(UserMixin, BaseFormMixin) {
  selectedUser: IUser = {}
  modalVisible = false
  reLoad = false
  loading = true
  users: IUser[] = []

  get userInfo(): IUser {
    return this.$store.getters.userInfo
  }

  @Watch('$store.getters.users')
  updateUsers() {
    this.users = cloneDeep(this.$store.getters.users)
  }

  async onCopy(data: IUser) {
    this.modalVisible = true
    const userCopy = cloneDeep(data) as IUser
    userCopy._id = ''
    userCopy.display_name = userCopy.display_name + ' Copy'
    this.selectedUser = userCopy
  }

  onAdd() {
    this.modalVisible = true
    this.selectedUser = {}
  }

  onCloseModal() {
    this.reLoad = true
    this.modalVisible = false
  }

  onEdit(data: IUser) {
    this.selectedUser = data
    this.modalVisible = true
  }

  async handleToggleActive(record: IUser) {
    const isSuccess = await this.$store.dispatch(ACTION_USER.UPDATE, record)
    if (!isSuccess) {
      record.is_active = false
    } else {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
  }

  async created() {
    await this.$store.dispatch(ACTION_USER.LOAD_ITEMS)
    this.loading = false
  }
}
