
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_USER } from '@/store/actions'
import { IUser } from '@/utils/types'
import UserMixin from '@/components/user/mixins/UserMixin.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { UserMixin },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class UserFormModal extends mixins(UserMixin, BaseFormDialogMixin) {
  @Prop()
  data!: IUser

  get requiredRule() {
    const requiredRule = {
      input_your_name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.register.input_your_name'),
      }),
      email: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.register.input_your_email'),
      }),
      password: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.register.input_your_password'),
      }),
    }
    return requiredRule
  }

  files: File[] = []

  form: IUser = {
    _id: '',
    email: '',
    password: '',
    is_sendmail_password: false,
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.users.add_new_user') : this.$t('label.users.edit_user')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let user = null
    if (this.isNew) {
      this.form.is_active = true
      user = await dispatch(ACTION_USER.ADD_NEW, {
        ...this.form,
      })
    } else {
      user = await dispatch(ACTION_USER.UPDATE, {
        ...this.form,
      })
    }
    if (user) {
      this.$emit('update:onSelect', user)
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
    this.visible = false
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
