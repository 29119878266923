<template>
  <UserList></UserList>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import UserList from '@/components/user/UserList.vue'

@Options({
  components: {
    UserList,
  },
  directives: { maska },
})
export default class User extends Vue {}
</script>
