
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_PROFILE } from '@/store/actions'
import { UserModel } from '@/models/user-model'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class ProfileSettings extends Vue {
  form = {
    display_name: '',
    new_password: '',
    confirm_password: '',
  }

  get requiredRule() {
    const requiredRule = {
      input_your_name: this.$t('validate.please_input_your_name'),
      email: this.$t('validate.please_input_your_valid_email'),
      password: this.$t('validate.please_input_your_valid_password'),
      password_again: this.$t('validate.please_input_your_valid_password_again'),
    }
    return requiredRule
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  onCancel() {
    this.$router.push({ name: 'home' })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    success = await dispatch(ACTION_PROFILE.UPDATE, {
      ...this.form,
    })

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })

      this.onCancel()
    }
  }

  async created() {
    this.form.display_name = this.userInfo.display_name ?? ''
  }
}
